<template>
  <div class="login-container">
    <h2 class = 'h2-ele'> 카카오톡 연결 </h2>

    <div v-if="webId != null">

      <a href="/kakao">kakao</a>

    </div>
    <div v-else class="text-center">
      <br />
      <SolidLoginButton />
    </div>

    <span v-if="error" style="color: #dc3545; font-size: 80%;">{{error}}</span>
  </div>
</template>

<script>
import queryString from 'querystring'
import axios from 'axios'
import OAuthRequest from '@/Entities/OAuthRequest'
import linq from 'linq'
import OAuthResult from '@/Entities/OAuthResult'
export default {
  name: 'AuthKakao',
  data() {
    return {
      error: ''
    }
  },
  props: {
    // clientId: {
    //     type: String,
    //     require: true
    // },
    //
    // clientSecret: {
    //     type: String,
    //     require: true
    // },
    //
    // callbackUri: {
    //     type: String,
    //     require: true
    // },
    //
    // addFriend: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendRequired: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendErrorTest: {
    //     type: String,
    //     require: false,
    //     default: 'Add me as a friend.'
    // },
    //
    loginText: {
      type: String,
      require: false,
      // default: 'LINE LOGIN'
      default: '로그인'
    }
  },
  components: {
    'SolidLoginButton': () => import('@/components/solid/SolidLoginButton'),
  },
  computed:{
    webId(){
      return this.$store.state.solid.webId
    },
  },
  async created() {
    //
    this.clientId = process.env.VUE_APP_KAKAO_CLIENT_ID
    this.clientSecret = process.env.VUE_APP_KAKAO_CLIENT_SECRET
    this.callbackUri = process.env.VUE_APP_KAKAO_CALLBACK_URL
    //this.$store.dispatch('line/getUserId');
    const { code } = queryString.parse(window.location.search.replace('?', ''))
    //if(!code) return
    // const { access_token } = localStorage.getItem("access_token")
    //
    // if(! access_token) return
    if(localStorage.getItem("kakao_access_token")===null) {
      if(!code) return
      const result = await this.getToken(code)
      // console.log("result:"+result)
      //save token local storage
      localStorage.setItem("kakao_access_token", result.token)
      this.$router.push('/kakao')
    }else{

      this.$router.push('/kakao')

      // var access_token = localStorage.getItem("kakao_access_token")
      //
      // console.log("kakao_access_token:"+access_token)
      // const {data} = await this.getProfile( access_token )
      //
      // console.log(data.id)
      //save state
      //this.$emit('result2', data.userId)
    }
  },
  methods: {
    async getToken(code) {
      const result = new OAuthRequest({
        code: code,
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        redirectUri: this.callbackUri
      })
      // console.log('clientSecret:'+this.clientSecret)
      //
      //
      //console.log('result:'+JSON.stringify(result))
      const params = new URLSearchParams()
      // console.log("before")
      linq.from(result.getPostable()).select(x => params.append(x.key, x.value)).toArray()
      // console.log("after param:"+params)
      const { data } = await axios.post('https://kauth.kakao.com/oauth/token', params)
      return new OAuthResult(data)
    },
  }
}
</script>

<style>
.login-container {
  text-align: center;
  margin-bottom: 250px;
}
.h2-ele {
  margin-top: 30px;
  margin-bottom: 100px;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
.btn-social {
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-block {
  display: block;
  /* width: 100%; */
  width: 90%;
  margin: auto;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 5px;
  height:50px;
}
.btn-line {
  /* color: #fff; */
  /* background-color: #00b900; */
  /* border-color: rgba(0,0,0,0.2); */
  color: #fff;
  background-color: #00b900;
  border-color: #00b900;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-social>:first-child {
  height: 32px;
  width: 32px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  /* border-right: 1px solid rgba(0,0,0,0.2); */
  /* border-right: 2px solid #00b900; */
  border: none
}
</style>